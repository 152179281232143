<template>
	<div>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">Logo</h1>
		<div class="settings-wrapper">
			<q-file
				v-model="logo"
				color="primary"
				label="Upload an image"
				class="q-my-sm"
				outlined
				accept=".png, .jpg"
				:hint="logo_hint"
				:error="hasError"
				:error-message="branding_errors['brand_logo']"
			>
				<template v-slot:prepend>
					<q-icon name="file_upload" @click.stop />
				</template>
				<template v-if="logo" v-slot:append>
					<q-icon name="cancel" @click.stop="cancel" class="cursor-pointer" />
				</template>
			</q-file>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'CustomBrandingLogo',
	data() {
		return {
			logo: null,
		}
	},
	computed: {
		...mapGetters(['custom_branding', 'branding_errors']),
		brand_logo() {
			return this.custom_branding.brand_logo
		},
		logo_hint() {
			if (this.brand_logo) {
				return `Current image: ${this.brand_logo}`
			}
			return `Current image:`
		},
		hasError() {
			return this.branding_errors['brand_logo'] ? true : false
		},
	},
	methods: {
		...mapActions(['updateCustomBrandLogo']),
		cancel() {
			this.logo = null
		},
	},
	watch: {
		logo: {
			deep: true,
			handler(val) {
				this.updateCustomBrandLogo(val)
			},
		},
	},
}
</script>
